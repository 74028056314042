import React, { useEffect, useMemo } from 'react';
import ReactWebChat, { createDirectLine, createStyleSet } from 'botframework-webchat';

import './WebChat.css';

const WebChat = ({ className, onFetchToken, store, token }) => {
  const directLine = useMemo(() => createDirectLine({ token }), [token]);

  const styleSet = useMemo(
    () =>
      createStyleSet({
        backgroundColor: 'Transparent',
        bubbleBackground: '#FFBF00',
        bubbleFromUserBackground: 'white',
        bubbleBorderRadius: '5px',
        bubbleBorderStyle: 'solid',
        bubbleBorderWidth: '1px',
        bubbleBorderColor: 'black',
        bubbleNubSize: 5,
        bubbleNubOffset: 'top',
        bubbleFromUserBorderRadius: '5px',
        bubbleFromUserBorderColor: 'black',
        bubbleFromUserNubSize: 5,
        bubbleFromUserNubOffset: 'top',
        bubbleFromUserBorderWidth: '0.5px',
        bubbleFromUserBorderStyle: 'solid'
      }),
    []
  );

  useEffect(() => {
    onFetchToken();
  }, [onFetchToken]);

  return token ? (
    <ReactWebChat className={`${className || ''} web-chat`} directLine={directLine} store={store} styleSet={styleSet} styleOptions={{ hideUploadButton: true }} />
  ) : (
    <div className={`${className || ''} connect-spinner`}>
      <div className="content">
        <div className="icon">
          <img height={'80px'} width={'80px'} src={process.env.PUBLIC_URL + '/favicon.svg'}/>
        </div>
        <p>Your session is being provisioned. Please wait.</p>
      </div>
    </div>
  );
};

export default WebChat;
