import React from 'react';

import MinimizableWebChat from './MinimizableWebChat';

const App = () => (
  <div>
    <div style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', top: '30%'}}>
      <h1 style={{ fontSize: '60px', textAlign: 'center', color: 'white', textShadow: '-4px 0 black, 0 4px black, 4px 0 black, 0 -4px black' }}>
        GenAId
      </h1>
      <h2 style={{ textAlign: 'center', color: 'white', textShadow: '2px 2px 0 black' }}>
        AI Solutions, Beyond the Ordinary
      </h2>
    </div>
    <MinimizableWebChat />
  </div>
  );

export default App;
